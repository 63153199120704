// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
  border-radius: 10px;
  // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  // box-shadow: $box-shadow;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

.csk:hover {
  background-image: linear-gradient(162deg, #4222e2 12.07%, #8f7cee 93.09%) !important;
  box-shadow: 0 6px 25px #6950e854 !important;
  color: #fff  !important;
  cursor: pointer;
}
.csk{
  background: rgb(244,241,254) !important;
  box-shadow: $box-shadow !important;
  color: black;

}

Button {
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, .2);
}