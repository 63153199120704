.blink {
  animation: blinker 2s linear infinite;
  color: rgb(255, 255, 255);
  font-weight: 700;
  margin-right: 20px;
}

.blink2 {
  /* animation: blinker 2s linear infinite;
    color: #ab62cd; */
  color: #09ffdd;
  font-weight: 700;
  margin-right: 20px;
}

.blink0 {
  animation: blinker 2s linear infinite;
  color: rgb(233, 4, 4);
  font-weight: 700;
  margin-right: 20px;
}

.blink1 {
  animation: blinker 2s linear infinite;
}


@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.title-info {
  color: #9BAAB2;
  font-size: 14px;
}

.booking-container .booking-header .booking-title {
  flex-grow: 1;
  color: #203247;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;

}

/* paginations  */

.pagination>li {
  display: inline-block;
  padding-left: 0;
}

.pagination>li {
  list-style: none;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  border: 1px solid #b785d9;
  line-height: 1.42857143;
  text-decoration: none;
  color: #b785d9 !important;
  /* background:#7ea2af ; */
}

.pagination>li.active>a {
  color: #fff;
  background-color: #b785d9;
  border-color: #fff;
}


/* tooltip  */

.tooltip {
  position: relative;
  cursor: pointer !important;
  opacity: 1 !important;
  z-index: 0 !important;
}

.tooltipText {
  background-color: #fff !important;
  position: absolute !important;
  top: 120% !important;
  /* left: 50% !important; */
  transform: translateX(-50%) !important;
  padding: 3px !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  opacity: 0 !important;
  transition: all .5s !important;
  border: 1px solid rgb(224, 224, 224);
}

.tooltip:hover .tooltipText {
  opacity: 1 !important;
}

.tw {
  color: #000000
}

.tooltipText::after {
  content: '';
  border-width: 5px !important;
  border-style: solid !important;
  border-color: #fff transparent transparent transparent !important;
  position: absolute !important;
  /* top: 100% !important; */
  left: 50% !important;
  transform: translateX(-50%) !important;
  margin-left: 5% !important;
}

.tooltip:hover .tooltipText {
  opacity: 1 !important;
  transform: translateY(-10px) !important;
}