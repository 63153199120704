
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 135px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
}